import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Play, Pause, SkipForward, Volume2, VolumeX, ChevronRight, RefreshCcw, Loader2 } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import useAudioStore from '../hooks/useAudioStore';

const SpeechStage = ({ 
    character, 
    text, 
    audioFile,
    questId,
    onNext,
    isTeam = false,
    teamId = null,
    role = ''
}) => {
    const [visible, setVisible] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [showTranscript, setShowTranscript] = useState(false);
    const [audioCompleted, setAudioCompleted] = useState(false);
    const [canProceed, setCanProceed] = useState(false);
    const [localError, setLocalError] = useState(null);
    const textRef = useRef(null);
    const animationTimeoutRef = useRef(null);
    
    const {
        sound,
        isPlaying,
        progress,
        duration,
        isLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        skip,
        setVolume,
        cleanup
    } = useAudioStore();

    useEffect(() => {
        setVisible(true);
        if (audioFile) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {
                    onend: () => {
                        setAudioCompleted(true);
                        setCanProceed(true);
                    },
                    onload: () => {
                        if (!audioFile) {
                            setCanProceed(true);
                        }
                    },
                    onloaderror: (id, error) => {
                        console.error('Audio load error:', error);
                        setLocalError('Failed to load audio file');
                        setCanProceed(true);
                    }
                });
            } catch (error) {
                console.error('Error initializing audio:', error);
                setLocalError('Error loading audio file');
                setCanProceed(true);
            }
        } else {
            setCanProceed(true);
        }

        return () => {
            setVisible(false);
            cleanup();
            if (animationTimeoutRef.current) {
                clearTimeout(animationTimeoutRef.current);
            }
        };
    }, [audioFile, questId, initSound, cleanup]);

    const handleNext = () => {
        setVisible(false);
        animationTimeoutRef.current = setTimeout(() => {
            onNext();
        }, 500);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        setVolume(isMuted ? 1 : 0);
    };

    const handleSkip = () => {
        skip();
        setAudioCompleted(true);
        setCanProceed(true);
    };

    const renderTextWithAnimation = () => {
        const words = text.split(' ');
        const totalDuration = 1000;
        const delayPerWord = totalDuration / words.length;

        return (
            <div className="space-x-1">
                {words.map((word, index) => (
                    <span
                        key={index}
                        className="inline-block opacity-0 animate-fade-in"
                        style={{
                            animationDelay: `${index * delayPerWord}ms`,
                            animationFillMode: 'forwards'
                        }}
                    >
                        {word}{' '}
                    </span>
                ))}
            </div>
        );
    };

    return (
        <Card className={`w-full max-w-3xl mx-auto transition-all duration-500 ${
            visible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
        }`}>
            <CardHeader>
                <CardTitle className="flex items-center justify-between">
                    <div className="text-xl md:text-2xl text-primary font-serif">
                        {character}
                        {isTeam && (
                            <span className="text-sm ml-2 text-muted-foreground">
                                ({role || 'No Role'})
                            </span>
                        )}
                    </div>
                    {audioFile && (
                        <div className="flex items-center space-x-2">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={toggleMute}
                                className="rounded-full p-2"
                            >
                                {isMuted ? (
                                    <VolumeX className="h-5 w-5 text-gray-500" />
                                ) : (
                                    <Volume2 className="h-5 w-5 text-gray-500" />
                                )}
                            </Button>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setShowTranscript(!showTranscript)}
                                className="text-sm"
                            >
                                {showTranscript ? 'Hide' : 'Show'} Transcript
                            </Button>
                        </div>
                    )}
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-4">
                {audioFile ? (
                    <div className="space-y-4">
                        <div className="flex items-center justify-center space-x-4">
                            <Button
                                onClick={togglePlayPause}
                                disabled={isLoading}
                                className="rounded-full w-12 h-12 flex items-center justify-center"
                            >
                                {isLoading ? (
                                    <Loader2 className="h-6 w-6 animate-spin" />
                                ) : isPlaying ? (
                                    <Pause className="h-6 w-6" />
                                ) : (
                                    <Play className="h-6 w-6" />
                                )}
                            </Button>
                            {!isTeam && (
                                <Button
                                    onClick={handleSkip}
                                    variant="outline"
                                    className="rounded-full"
                                >
                                    <SkipForward className="h-5 w-5" />
                                </Button>
                            )}
                        </div>

                        <div className="space-y-2">
                            <div className="w-full bg-gray-200 rounded-full h-2">
                                <div
                                    className="bg-primary rounded-full h-full transition-all duration-300"
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                            <div className="flex items-center justify-between text-sm text-muted-foreground">
                                <span>Progress: {Math.round(progress)}%</span>
                                <div className="flex items-center gap-2">
                                    <Volume2 className="h-4 w-4" />
                                    <input 
                                        type="range"
                                        min="0"
                                        max="100"
                                        className="w-24"
                                        onChange={(e) => setVolume(e.target.value / 100)}
                                    />
                                </div>
                            </div>
                        </div>

                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                ) : null}

                {localError && (
                    <Alert variant="destructive">
                        <AlertDescription>{localError}</AlertDescription>
                    </Alert>
                )}

                <div
                    ref={textRef}
                    className={`prose max-w-none transition-opacity duration-500 ${
                        showTranscript || !audioFile ? 'opacity-100' : 'opacity-0'
                    }`}
                >
                    {renderTextWithAnimation()}
                </div>

                <div className="flex justify-center pt-4">
                    <Button
                        onClick={handleNext}
                        disabled={!canProceed}
                        className="transition-all duration-300 hover:scale-105 group"
                    >
                        Continue
                        <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                    </Button>
                </div>

                {!canProceed && audioFile && (
                    <div className="text-center text-sm text-muted-foreground animate-pulse">
                        Please listen to the audio before continuing
                    </div>
                )}
            </CardContent>
        </Card>
    );
};

SpeechStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    onNext: PropTypes.func.isRequired,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string
};

export default SpeechStage;

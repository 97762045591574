import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Alert, AlertDescription } from './ui/alert';
import api from '../api';
import { globalWebSocketManager } from '../socket';
import {
    Users,
    Copy,
    CheckCircle,
    Loader2,
    Play,
    AlertCircle,
    Crown,
    Shield,
    Clock,
    UserPlus,
    MessageSquare,
    Bell
} from 'lucide-react';

const TeamWaitingRoom = () => {
    const { teamId } = useParams();
    const navigate = useNavigate();
    const [team, setTeam] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [error, setError] = useState(null);
    const [copied, setCopied] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [isUpdatingRole, setIsUpdatingRole] = useState(false);
    const [isConnecting, setIsConnecting] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const initializeTeam = useCallback(async () => {
        try {
            setIsConnecting(true);
            // Загружаем данные команды
            const [teamResponse, profileResponse] = await Promise.all([
                api.get(`/team/status/${teamId}`),
                api.get('/account/profile')
            ]);

            if (teamResponse.data.status === 'in_progress') {
                navigate(`/quest/${teamResponse.data.quest_id}/step/1/substep/0`);
                return;
            }

            setTeam(teamResponse.data);
            setCurrentUser(profileResponse.data);

            // Инициализируем WebSocket соединение
            const disconnectHandler = () => {
                setIsConnected(false);
                setError('Connection lost. Reconnecting...');
            };

            const connectHandler = () => {
                setIsConnected(true);
                setError(null);
            };

            const stepCompletionHandler = (data) => {
                const { username, step, substep } = data;
                addNotification(`${username} completed step ${step}.${substep}`);
            };

            const roleUpdateHandler = (data) => {
                const { username, role } = data;
                addNotification(`${username} selected role: ${role}`);
                updateTeamMemberRole(data);
            };

            globalWebSocketManager.addEventListener('connection_status', connectHandler);
            globalWebSocketManager.addEventListener('disconnect', disconnectHandler);
            globalWebSocketManager.addEventListener('step_completed', stepCompletionHandler);
            globalWebSocketManager.addEventListener('role_update', roleUpdateHandler);

            await globalWebSocketManager.connect(teamId, true);
            setIsConnecting(false);

            return () => {
                globalWebSocketManager.removeEventListener('connection_status', connectHandler);
                globalWebSocketManager.removeEventListener('disconnect', disconnectHandler);
                globalWebSocketManager.removeEventListener('step_completed', stepCompletionHandler);
                globalWebSocketManager.removeEventListener('role_update', roleUpdateHandler);
                globalWebSocketManager.disconnect();
            };

        } catch (error) {
            console.error('Error initializing team:', error);
            setError(error.response?.data?.detail || 'Failed to load team information');
            setIsConnecting(false);
        }
    }, [teamId, navigate]);

    useEffect(() => {
        const cleanup = initializeTeam();
        return () => {
            if (cleanup) cleanup();
        };
    }, [initializeTeam]);

    const addNotification = (message) => {
        setNotifications(prev => [
            { id: Date.now(), message, timestamp: new Date() },
            ...prev.slice(0, 9)
        ]);
    };

    const updateTeamMemberRole = (data) => {
        setTeam(prevTeam => {
            if (!prevTeam) return null;

            const updatedMembers = prevTeam.members.map(member => {
                if (member.user_id === data.user_id) {
                    return { ...member, role: data.role };
                }
                return member;
            });

            return { ...prevTeam, members: updatedMembers };
        });
    };

    const copyTeamCode = async () => {
        try {
            await navigator.clipboard.writeText(team.code);
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        } catch (err) {
            setError('Failed to copy team code');
        }
    };

    const handleRoleSelect = async (role) => {
        if (!currentUser) return;

        try {
            setIsUpdatingRole(true);
            setError(null);

            const response = await api.post(`/team/${teamId}/update-role`, {
                role: role,
                user_id: currentUser._id
            });

            if (response.data) {
                setSelectedRole(role);
                if (globalWebSocketManager.isConnected()) {
                    globalWebSocketManager.send({
                        type: 'role_update',
                        data: {
                            teamId,
                            userId: currentUser._id,
                            username: currentUser.username,
                            role
                        }
                    });
                }
                await initializeTeam();
            }
        } catch (error) {
            console.error('Error updating role:', error);
            setError(error.response?.data?.detail || 'Failed to update role');
            setSelectedRole('');
        } finally {
            setIsUpdatingRole(false);
        }
    };

    const handleStartQuest = async () => {
        if (!team) return;

        try {
            const allHaveRoles = team.members.every(member => member.role);
            if (!allHaveRoles) {
                setError('All team members must select roles before starting');
                return;
            }

            const response = await api.post(`/team/${teamId}/start`);
            if (response.data) {
                if (globalWebSocketManager.isConnected()) {
                    globalWebSocketManager.send({
                        type: 'quest_started',
                        data: {
                            teamId,
                            questId: team.quest_id,
                            timestamp: new Date().toISOString(),
                        }
                    });
                }
                navigate(`/quest/${team.quest_id}/step/1/substep/0`);
            }
        } catch (error) {
            console.error('Error starting quest:', error);
            setError(error.response?.data?.detail || 'Failed to start quest');
        }
    };

    if (isConnecting || !team) {
        return (
            <Card className="w-full max-w-md mx-auto">
                <CardContent className="p-6 text-center">
                    <Loader2 className="w-8 h-8 animate-spin mx-auto mb-4" />
                    <p>Connecting to team...</p>
                </CardContent>
            </Card>
        );
    }

    // Определяем, является ли текущий пользователь лидером команды
    const isLeader = currentUser && team.members.some(
        m => m.user_id === currentUser._id && m.is_leader
    );

    // Находим информацию о текущем пользователе в команде
    const currentMember = currentUser && team.members.find(
        m => m.user_id === currentUser._id
    );

    return (
        <Card className="w-full max-w-2xl mx-auto">
            <CardHeader>
                <CardTitle className="flex items-center gap-2">
                    <Users className="h-6 w-6" />
                    {team.name}
                </CardTitle>
                <CardDescription>
                    Waiting for team members to join and select roles
                </CardDescription>
            </CardHeader>

            <CardContent className="space-y-6">
                {error && (
                    <Alert variant="destructive">
                        <AlertCircle className="h-4 w-4" />
                        <AlertDescription>{error}</AlertDescription>
                    </Alert>
                )}

                <div className="bg-background/95 p-4 rounded-lg border">
                    <div className="text-sm text-muted-foreground mb-2">
                        Share this team code:
                    </div>
                    <div className="flex items-center gap-2">
                        <code className="bg-background px-2 py-1 rounded text-lg font-mono">
                            {team.code}
                        </code>
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={copyTeamCode}
                            className="h-8 px-2"
                        >
                            {copied ? (
                                <CheckCircle className="h-4 w-4 text-green-500" />
                            ) : (
                                <Copy className="h-4 w-4" />
                            )}
                        </Button>
                    </div>
                </div>

                {currentMember && !currentMember.role && (
                    <div className="space-y-2">
                        <h3 className="text-sm font-medium">Select Your Role:</h3>
                        <div className="grid grid-cols-3 gap-2">
                            {['Gamer', 'Listener', 'Regular'].map((role) => {
                                const isDisabled = role === 'Gamer' &&
                                    team.members.filter(m => m.role === 'Gamer').length >= 2;

                                return (
                                    <Button
                                        key={role}
                                        variant={selectedRole === role ? 'default' : 'outline'}
                                        onClick={() => handleRoleSelect(role)}
                                        disabled={isUpdatingRole || isDisabled}
                                        className="flex items-center gap-2"
                                    >
                                        <Shield className="h-4 w-4" />
                                        {role}
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                )}

                <div className="space-y-4">
                    <div className="flex items-center justify-between">
                        <h3 className="font-semibold">
                            Team Members ({team.members.length}/{team.max_participants})
                        </h3>
                        {notifications.length > 0 && (
                            <div className="flex items-center gap-1">
                                <Bell className="h-4 w-4 text-primary" />
                                <span className="text-sm text-muted-foreground">
                                    {notifications.length} updates
                                </span>
                            </div>
                        )}
                    </div>

                    <div className="space-y-2">
                        {team.members.map((member) => (
                            <div
                                key={member.user_id}
                                className="flex items-center justify-between p-3 bg-background/50 rounded-lg"
                            >
                                <div className="flex items-center gap-2">
                                    {member.is_leader && (
                                        <Crown className="h-4 w-4 text-yellow-500" />
                                    )}
                                    <span className="font-medium">{member.username}</span>
                                </div>
                                <div className="flex items-center gap-2">
                                    {member.role ? (
                                        <span className={`text-sm font-medium ${
                                            member.role === 'Gamer' ? 'text-green-500' :
                                            member.role === 'Listener' ? 'text-blue-500' :
                                            'text-purple-500'
                                        }`}>
                                            {member.role}
                                        </span>
                                    ) : (
                                        <span className="text-sm text-gray-400">
                                            Selecting role...
                                        </span>
                                    )}
                                </div>
                            </div>
                        ))}

                        {Array.from({ length: team.max_participants - team.members.length }).map((_, i) => (
                            <div
                                key={`empty-${i}`}
                                className="flex items-center justify-center p-3 bg-background/50 rounded-lg border-dashed border-2 border-muted"
                            >
                                <UserPlus className="h-5 w-5 text-muted-foreground" />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Notifications Panel */}
                {notifications.length > 0 && (
                    <div className="mt-4 border rounded-lg p-4">
                        <div className="flex items-center justify-between mb-3">
                            <h4 className="text-sm font-medium flex items-center gap-2">
                                <MessageSquare className="h-4 w-4" />
                                Recent Updates
                            </h4>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => setNotifications([])}
                            >
                                Clear
                            </Button>
                        </div>
                        <div className="space-y-2 max-h-40 overflow-y-auto">
                            {notifications.map(notification => (
                                <div
                                    key={notification.id}
                                    className="text-sm text-muted-foreground flex justify-between items-center"
                                >
                                    <span>{notification.message}</span>
                                    <span className="text-xs">
                                        {notification.timestamp.toLocaleTimeString()}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {isLeader && (
                    <Button
                        className="w-full"
                        onClick={handleStartQuest}
                        disabled={
                            team.members.length < team.max_participants ||
                            team.members.some(m => !m.role)
                        }
                    >
                        {team.members.length < team.max_participants ? (
                            <>
                                <Clock className="mr-2 h-4 w-4" />
                                Waiting for all members to join...
                            </>
                        ) : team.members.some(m => !m.role) ? (
                            <>
                                <Clock className="mr-2 h-4 w-4" />
                                Waiting for role selection...
                            </>
                        ) : (
                            <>
                                <Play className="mr-2 h-4 w-4" />
                                Start Quest
                            </>
                        )}
                    </Button>
                )}

                {!isLeader && (
                    <Alert>
                        <AlertDescription>
                            Waiting for team leader to start the quest...
                        </AlertDescription>
                    </Alert>
                )}

                <Alert className="bg-primary/10 border-primary/20">
                    <AlertDescription className="space-y-2">
                        <h4 className="font-medium">Role Limits:</h4>
                        <ul className="list-disc list-inside space-y-1 text-sm">
                            <li>Maximum 2 Gamers per team</li>
                            <li>No limit for Listeners</li>
                            <li>No limit for Regular members</li>
                        </ul>
                    </AlertDescription>
                </Alert>
            </CardContent>
        </Card>
    );
};

export default TeamWaitingRoom;

import { useState, useEffect, useCallback, useRef } from 'react';
import { globalWebSocketManager } from '../socket';
import log from 'loglevel';

const useSocket = (id, isTeam = false) => {
  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState(null);
  const manager = useRef(globalWebSocketManager);
  const mountedRef = useRef(true);
  const reconnectTimeoutRef = useRef(null);
  const eventListenersRef = useRef(new Map());

  // Подключение к WebSocket
  useEffect(() => {
    const connect = async () => {
      try {
        log.debug(`Connecting to ${isTeam ? 'team' : 'quest'} ${id}`);
        await manager.current.connect(id, isTeam);
        if (mountedRef.current) {
          setIsConnected(true);
          setError(null);
        }
      } catch (err) {
        if (mountedRef.current) {
          log.error('Connection error:', err);
          setError(err);
          setIsConnected(false);
        }
      }
    };

    const handleConnectionStatus = (status) => {
      if (mountedRef.current) {
        setIsConnected(status.connected);
        if (!status.connected && status.error) {
          setError(status.error);
        }
      }
    };

    const handleConnectionError = (err) => {
      if (mountedRef.current) {
        setError(err);
        setIsConnected(false);
      }
    };

    // Обработчики для командного режима
    const handleTeamEvent = (event) => {
      const listener = eventListenersRef.current.get(event.type);
      if (listener) {
        listener(event.data);
      }
    };

    // Добавляем слушатели
    manager.current.addEventListener('connection_status', handleConnectionStatus);
    manager.current.addEventListener('connection_error', handleConnectionError);
    
    if (isTeam) {
      // Добавляем слушатели для командных событий
      manager.current.addEventListener('team_event', handleTeamEvent);
      manager.current.addEventListener('step_completed', handleTeamEvent);
      manager.current.addEventListener('role_update', handleTeamEvent);
      manager.current.addEventListener('team_progressed', handleTeamEvent);
      manager.current.addEventListener('member_connected', handleTeamEvent);
      manager.current.addEventListener('member_disconnected', handleTeamEvent);
      manager.current.addEventListener('team_state_update', handleTeamEvent);
    }

    // Устанавливаем соединение
    connect();

    // Очистка при размонтировании
    return () => {
      mountedRef.current = false;
      manager.current.removeEventListener('connection_status', handleConnectionStatus);
      manager.current.removeEventListener('connection_error', handleConnectionError);
      
      if (isTeam) {
        manager.current.removeEventListener('team_event', handleTeamEvent);
        manager.current.removeEventListener('step_completed', handleTeamEvent);
        manager.current.removeEventListener('role_update', handleTeamEvent);
        manager.current.removeEventListener('team_progressed', handleTeamEvent);
        manager.current.removeEventListener('member_connected', handleTeamEvent);
        manager.current.removeEventListener('member_disconnected', handleTeamEvent);
        manager.current.removeEventListener('team_state_update', handleTeamEvent);
      }
      
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [id, isTeam]);

  // Отправка сообщения
  const sendMessage = useCallback((message) => {
    if (!isConnected) {
      log.warn('Attempting to send message while disconnected:', message);
    }

    const enrichedMessage = {
      ...message,
      teamId: isTeam ? id : undefined,
      timestamp: new Date().toISOString()
    };

    manager.current.send(enrichedMessage);
  }, [isConnected, id, isTeam]);

  // Добавление слушателя события
  const addEventListener = useCallback((event, callback) => {
    if (typeof callback !== 'function') {
      log.error('Event listener callback must be a function');
      return () => {};
    }

    eventListenersRef.current.set(event, callback);

    if (isTeam) {
      // Для командных событий подписываемся на конкретный тип
      manager.current.addEventListener(event, (data) => {
        if (mountedRef.current) {
          callback(data);
        }
      });
    }

    return () => {
      eventListenersRef.current.delete(event);
      if (isTeam) {
        manager.current.removeEventListener(event, callback);
      }
    };
  }, [isTeam]);

  // Удаление слушателя события
  const removeEventListener = useCallback((event, callback) => {
    eventListenersRef.current.delete(event);
    if (isTeam) {
      manager.current.removeEventListener(event, callback);
    }
  }, [isTeam]);

  // Переподключение
  const reconnect = useCallback(async () => {
    try {
      log.info('Manually reconnecting...');
      await manager.current.connect(id, isTeam);
      if (mountedRef.current) {
        setIsConnected(true);
        setError(null);
      }
    } catch (err) {
      if (mountedRef.current) {
        setError(err);
        setIsConnected(false);
      }
    }
  }, [id, isTeam]);

  // Получение текущего статуса соединения
  const getConnectionState = useCallback(() => {
    return manager.current.getConnectionState();
  }, []);

  // Отправка командного события
  const sendTeamEvent = useCallback((eventType, eventData) => {
    if (!isTeam) {
      log.warn('Attempting to send team event in non-team mode');
      return;
    }

    sendMessage({
      type: eventType,
      data: {
        ...eventData,
        teamId: id,
        timestamp: new Date().toISOString()
      }
    });
  }, [isTeam, id, sendMessage]);

  return {
    isConnected,
    error,
    sendMessage,
    addEventListener,
    removeEventListener,
    reconnect,
    getConnectionState,
    sendTeamEvent,
    connectionState: getConnectionState()
  };
};

export default useSocket;

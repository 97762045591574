import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import StartScreen from './components/StartScreen';
import Account from './components/Account';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancel from './components/PaymentCancel';
import QuestPage from './components/QuestPage';
import QuestContent from './components/QuestContent';
import TeamCreate from './components/TeamCreate';
import TeamWaitingRoom from './components/TeamWaitingRoom';
import TeamJoin from './components/TeamJoin';
import Login from './components/Login';
import Register from './components/Register';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorMessage from './components/ErrorMessage';
import { useQuest } from './hooks/useQuest';
import { QuestStateProvider } from './components/QuestStateProvider';
import { globalWebSocketManager } from './socket';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate
} from 'react-router-dom';
import { Card, CardContent } from './components/ui/card';
import api from './api';

const QuestRoute = ({ initialQuest, isLoading }) => {
    const { id: questId } = useParams();
    const navigate = useNavigate();
    const [localError, setLocalError] = useState(null);
    const [questState, setQuestState] = useState(null);
    const [loadingState, setLoadingState] = useState(true);
    const { quest, loading: questLoading, error: questError } = useQuest(questId);

    // Get quest mode from localStorage
    const questMode = localStorage.getItem(`questMode_${questId}`);
    const questModeData = questMode ? JSON.parse(questMode) : null;
    const isTeam = questModeData?.isTeam;
    const teamId = questModeData?.teamId;
    const role = questModeData?.role;

    useEffect(() => {
        let mounted = true;
        const fetchQuestState = async () => {
            try {
                let response;
                if (isTeam && teamId) {
                    response = await api.get(`/team/status/${teamId}`);
                } else {
                    response = await api.get(`/quest-state/${questId}`);
                }

                if (!mounted) return;

                const stateData = response.data;
                setQuestState({
                    questId,
                    currentStep: stateData.currentStep || 1,
                    currentSubStep: stateData.currentSubStep || 0,
                    points: stateData.points || 100,
                    role: stateData.role || role,
                    isTeam,
                    teamId: stateData.teamId || teamId,
                    incorrectAttempts: stateData.incorrectAttempts || 0,
                    usedHints: stateData.usedHints || [],
                    completedSteps: stateData.completedSteps || [],
                    error: null,
                });
                setLoadingState(false);

                // Update route according to current state
                navigate(
                    `/quest/${questId}/step/${stateData.currentStep || 1}/substep/${stateData.currentSubStep || 0}`,
                    { replace: true }
                );
            } catch (error) {
                console.error('Error fetching quest state:', error);
                if (mounted) {
                    setLocalError('Failed to load quest state');
                    setLoadingState(false);
                }
            }
        };

        if (quest) {
            fetchQuestState();
        }

        return () => {
            mounted = false;
        };
    }, [quest, isTeam, teamId, questId, role, navigate]);

    if (questLoading || loadingState) {
        return <LoadingSpinner message="Loading quest..." />;
    }

    if (questError || localError) {
        return <ErrorMessage message={questError || localError} />;
    }

    if (!quest) {
        return <ErrorMessage message="Quest not found" />;
    }

    if (!questState) {
        return <LoadingSpinner message="Initializing quest..." />;
    }

    return (
        <QuestStateProvider initialState={questState}>
            <QuestContent quest={quest} onError={setLocalError} />
        </QuestStateProvider>
    );
};

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    return token ? children : <Navigate to="/login" />;
};

const App = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [quest, setQuest] = useState(() => {
        const saved = localStorage.getItem('currentQuest');
        return saved ? JSON.parse(saved) : null;
    });

    useEffect(() => {
        if (quest) {
            localStorage.setItem('currentQuest', JSON.stringify(quest));
        }
    }, [quest]);

    const handleStartQuest = useCallback(
        async (questId, selectedRole, teamId = null) => {
            try {
                setIsLoading(true);
                setError(null);

                // Save quest mode data
                const questModeData = {
                    isTeam: !!teamId,
                    role: selectedRole,
                    teamId: teamId
                };
                localStorage.setItem(`questMode_${questId}`, JSON.stringify(questModeData));

                // Get quest data
                const response = await api.get(`/quests/${questId}`);
                setQuest(response.data);
                localStorage.setItem('currentQuest', JSON.stringify(response.data));

                // Initialize WebSocket
                await globalWebSocketManager.connect(questId, !!teamId);

                // Send start quest message
                globalWebSocketManager.send({
                    type: 'start_quest',
                    data: {
                        questId,
                        role: selectedRole,
                        teamId,
                        timestamp: new Date().toISOString(),
                    },
                });

                setIsLoading(false);
                return `/quest/${questId}`;
            } catch (error) {
                console.error('Error starting quest:', error);
                setError(error.message || 'Failed to start quest');
                setIsLoading(false);
                throw error;
            }
        },
        []
    );

    const handleError = useCallback((error) => {
        console.error('Application error:', error);
        setError(
            error.response?.data?.message ||
            error.message ||
            'An unexpected error occurred'
        );
        setIsLoading(false);
    }, []);

    const clearError = useCallback(() => {
        setError(null);
    }, []);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            globalWebSocketManager.disconnect();
        };
    }, []);

    return (
        <Router>
            <div className="min-h-screen bg-background">
                {error && (
                    <ErrorMessage
                        message={error}
                        retryAction={clearError}
                        timeout={5000}
                    />
                )}
                <Routes>
                    <Route path="/" element={<StartScreen />} />
                    <Route
                        path="/account"
                        element={
                            <ProtectedRoute>
                                <Account />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    
                    <Route
                        path="/quest/:id/start"
                        element={
                            <ProtectedRoute>
                                <QuestPage onStart={handleStartQuest} isLoading={isLoading} />
                            </ProtectedRoute>
                        }
                    />
                    
                    <Route
                        path="/quest/:id/create-team"
                        element={
                            <ProtectedRoute>
                                <TeamCreate />
                            </ProtectedRoute>
                        }
                    />
                    
                    <Route
                        path="/quest/:id/join-team"
                        element={
                            <ProtectedRoute>
                                <TeamJoin />
                            </ProtectedRoute>
                        }
                    />
                    
                    <Route
                        path="/team/:teamId/waiting"
                        element={
                            <ProtectedRoute>
                                <TeamWaitingRoom />
                            </ProtectedRoute>
                        }
                    />

                    <Route path="/payment-success" element={<PaymentSuccess />} />
                    <Route path="/payment-cancel" element={<PaymentCancel />} />
                    
                    <Route
                        path="/quest/:id/*"
                        element={
                            <ProtectedRoute>
                                <React.Suspense
                                    fallback={<LoadingSpinner message="Loading quest content..." />}
                                >
                                    <QuestRoute initialQuest={quest} isLoading={isLoading} />
                                </React.Suspense>
                            </ProtectedRoute>
                        }
                    />

                    <Route path="/quest" element={<Navigate to="/" replace />} />
                    
                    <Route
                        path="*"
                        element={
                            <Card className="max-w-md mx-auto mt-8">
                                <CardContent className="p-6 text-center">
                                    <h2 className="text-2xl font-bold mb-4">Page Not Found</h2>
                                    <p className="mb-4">The page you're looking for doesn't exist.</p>
                                    <button 
                                        onClick={() => window.history.back()}
                                        className="btn btn-primary"
                                    >
                                        Go Back
                                    </button>
                                </CardContent>
                            </Card>
                        }
                    />
                </Routes>

                {isLoading && (
                    <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
                        <LoadingSpinner message="Loading..." />
                    </div>
                )}
            </div>
        </Router>
    );
};

export default App;

import log from 'loglevel';

log.setLevel('DEBUG');

class WebSocketManager {
  constructor() {
    this.socket = null;
    this.listeners = new Map();
    this.reconnectAttempts = 0;
    this.pendingMessages = [];
    this.connectionPromise = null;
    this.heartbeatInterval = null;
    this.currentId = null;
    this.isTeam = false;
    
    // Константы
    this.MAX_RECONNECT_ATTEMPTS = 5;
    this.RECONNECT_DELAY = 1000;
    this.CONNECTION_TIMEOUT = 10000;
    this.HEARTBEAT_INTERVAL = 30000;
  }

  getWebSocketUrl(id, isTeam) {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const baseUrl = `${protocol}//${window.location.host}/ws`;
    return isTeam ? `${baseUrl}/team/${id}` : `${baseUrl}/${id}`;
  }

  async connect(id, isTeam = false) {
    if (this.connectionPromise) {
      return this.connectionPromise;
    }

    this.currentId = id;
    this.isTeam = isTeam;

    this.connectionPromise = new Promise((resolve, reject) => {
      try {
        if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
          log.debug('Closing existing WebSocket connection');
          this.socket.close();
        }

        const wsUrl = this.getWebSocketUrl(id, isTeam);
        log.debug(`Connecting to WebSocket: ${wsUrl}`);
        this.socket = new WebSocket(wsUrl);

        const connectionTimeout = setTimeout(() => {
          if (this.socket.readyState !== WebSocket.OPEN) {
            this.socket.close();
            reject(new Error('WebSocket connection timeout'));
          }
        }, this.CONNECTION_TIMEOUT);

        this.socket.onopen = () => {
          clearTimeout(connectionTimeout);
          this.reconnectAttempts = 0;
          this.startHeartbeat();
          this.processPendingMessages();
          this.notifyListeners('connection_status', { connected: true });
          log.info(`WebSocket connection established for ${isTeam ? 'team' : 'quest'} ${id}`);
          resolve();
        };

        this.socket.onclose = async (event) => {
          clearTimeout(connectionTimeout);
          this.cleanup();
          log.info('WebSocket connection closed', event);

          if (!event.wasClean && this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
            this.reconnectAttempts++;
            const delay = Math.min(
              this.RECONNECT_DELAY * Math.pow(2, this.reconnectAttempts),
              30000
            );
            log.info(
              `Attempting to reconnect in ${delay}ms (${this.reconnectAttempts}/${this.MAX_RECONNECT_ATTEMPTS})`
            );

            setTimeout(() => {
              this.connect(this.currentId, this.isTeam);
            }, delay);
          }
          this.notifyListeners('connection_status', { connected: false });
        };

        this.socket.onerror = (error) => {
          log.error('WebSocket error:', error);
          this.notifyListeners('connection_error', error);
        };

        this.socket.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);
            log.debug('Received message:', message);

            if (message.type === 'heartbeat') {
              this.send({
                type: 'heartbeat_ack',
                timestamp: new Date().toISOString()
              });
              return;
            }

            this.notifyListeners(message.type, message.data);
          } catch (error) {
            log.error('Error processing message:', error);
          }
        };

      } catch (error) {
        log.error('Error in connect:', error);
        reject(error);
      }
    });

    return this.connectionPromise;
  }

  cleanup() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    this.socket = null;
    this.connectionPromise = null;
  }

  disconnect() {
    if (this.socket) {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.send({
          type: 'disconnect',
          timestamp: new Date().toISOString()
        });
      }
      this.socket.close();
      this.cleanup();
    }
  }

  startHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }
    this.heartbeatInterval = setInterval(() => {
      if (this.socket?.readyState === WebSocket.OPEN) {
        this.send({
          type: 'heartbeat',
          timestamp: new Date().toISOString()
        });
      }
    }, this.HEARTBEAT_INTERVAL);
  }

  send(message) {
    const messageString = JSON.stringify({
      ...message,
      timestamp: new Date().toISOString()
    });

    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.send(messageString);
      log.debug('Message sent:', message);
    } else {
      log.debug('Queuing message:', message);
      this.pendingMessages.push(messageString);
    }
  }

  processPendingMessages() {
    while (this.pendingMessages.length > 0) {
      const message = this.pendingMessages.shift();
      if (this.socket?.readyState === WebSocket.OPEN) {
        this.socket.send(message);
      }
    }
  }

  addEventListener(event, callback) {
    if (!this.listeners.has(event)) {
      this.listeners.set(event, new Set());
    }
    this.listeners.get(event).add(callback);
    log.debug(`Added listener for event: ${event}`);
  }

  removeEventListener(event, callback) {
    if (this.listeners.has(event)) {
      this.listeners.get(event).delete(callback);
      if (this.listeners.get(event).size === 0) {
        this.listeners.delete(event);
      }
      log.debug(`Removed listener for event: ${event}`);
    }
  }

  notifyListeners(event, data) {
    const listeners = this.listeners.get(event);
    if (listeners) {
      listeners.forEach(callback => {
        try {
          callback(data);
        } catch (error) {
          log.error(`Error in listener for event ${event}:`, error);
        }
      });
    }
  }

  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  getConnectionState() {
    if (!this.socket) return 'CLOSED';
    return ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'][this.socket.readyState];
  }
}

export const globalWebSocketManager = new WebSocketManager();

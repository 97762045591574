import React, {
    createContext,
    useContext,
    useState,
    useCallback,
    useEffect,
    useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { globalWebSocketManager } from '../socket';
import api from '../api';

const QuestStateContext = createContext(null);

export const useQuestState = () => {
    const context = useContext(QuestStateContext);
    if (!context) {
        throw new Error('useQuestState must be used within a QuestStateProvider');
    }
    return context;
};

export const QuestStateProvider = ({ children, initialState }) => {
    const navigate = useNavigate();
    const [state, setState] = useState(() => ({
        questId: '',
        currentStep: 1,
        currentSubStep: 0,
        points: 100,
        role: '',
        isTeam: false,
        teamId: null,
        incorrectAttempts: 0,
        usedHints: [],
        completedSteps: [],
        error: null,
        lastUpdated: new Date(),
        ...initialState,
    }));

    const [isSyncing, setIsSyncing] = useState(false);
    const lastUpdateRef = useRef(null);
    const updateTimeoutRef = useRef(null);
    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const syncToStorage = useCallback((newState) => {
        try {
            const storageKey = newState.isTeam ? 
                `teamState_${newState.teamId}` : 
                `questState_${newState.questId}`;
            localStorage.setItem(storageKey, JSON.stringify(newState));
        } catch (error) {
            console.error('Error syncing to storage:', error);
        }
    }, []);

    const updateState = useCallback(async (updates, skipSync = false) => {
        if (isSyncing) return;

        try {
            setIsSyncing(true);
            const timestamp = new Date();

            // Проверка дупликатов
            if (lastUpdateRef.current &&
                JSON.stringify(lastUpdateRef.current.data) === JSON.stringify(updates) &&
                timestamp - lastUpdateRef.current.timestamp < 1000) {
                return;
            }

            const newState = {
                ...stateRef.current,
                ...updates,
                lastUpdated: timestamp,
            };

            setState(newState);
            lastUpdateRef.current = { data: updates, timestamp };

            if (!skipSync) {
                syncToStorage(newState);

                if (updateTimeoutRef.current) {
                    clearTimeout(updateTimeoutRef.current);
                }

                updateTimeoutRef.current = setTimeout(async () => {
                    try {
                        // Подготовка данных для отправки
                        const stateData = {
                            current_step: newState.currentStep,
                            current_substep: newState.currentSubStep,
                            points: newState.points,
                            role: newState.role,
                            is_team: newState.isTeam,
                            team_id: newState.teamId,
                            incorrect_attempts: newState.incorrectAttempts,
                            used_hints: newState.usedHints,
                            completed_steps: newState.completedSteps,
                            status: 'in_progress'
                        };

                        if (newState.isTeam) {
                            await api.post(`/team/status/${newState.teamId}`, stateData);
                        } else {
                            await api.post(`/quest-state/${newState.questId}`, stateData);
                        }

                        // Отправка WebSocket сообщения
                        if (globalWebSocketManager.isConnected()) {
                            globalWebSocketManager.send({
                                type: 'state_update',
                                data: {
                                    ...updates,
                                    questId: newState.questId,
                                    teamId: newState.teamId,
                                    isTeam: newState.isTeam,
                                    timestamp: timestamp.toISOString(),
                                },
                            });
                        }
                    } catch (error) {
                        console.error('Error syncing state:', error);
                    }
                }, 300);
            }
        } finally {
            setIsSyncing(false);
        }
    }, [isSyncing, syncToStorage]);

    const addIncorrectAttempt = useCallback(async () => {
        const currentState = stateRef.current;
        const newPoints = Math.max(0, currentState.points - 5);
        const newAttempts = (currentState.incorrectAttempts || 0) + 1;

        await updateState({
            points: newPoints,
            incorrectAttempts: newAttempts,
        });

        return { points: newPoints, attempts: newAttempts };
    }, [updateState]);

    const applyHint = useCallback(async (hintId) => {
        const currentState = stateRef.current;
        if (currentState.usedHints.includes(hintId)) {
            return;
        }

        const newPoints = Math.max(0, currentState.points - 10);
        const newHints = [...currentState.usedHints, hintId];

        await updateState({
            points: newPoints,
            usedHints: newHints,
        });

        return { points: newPoints, usedHints: newHints };
    }, [updateState]);

    useEffect(() => {
        const handleStateUpdate = (data) => {
            if (!data?.timestamp || isSyncing) return;

            const currentState = stateRef.current;
            const newTimestamp = new Date(data.timestamp);
            const lastUpdateTimestamp = currentState.lastUpdated 
                ? new Date(currentState.lastUpdated)
                : new Date(0);

            if (newTimestamp > lastUpdateTimestamp) {
                if (currentState.isTeam && data.teamId !== currentState.teamId) {
                    return;
                }
                updateState(data, true);
            }
        };

        const cleanup = globalWebSocketManager.addEventListener('state_update', handleStateUpdate);
        return () => {
            if (cleanup) cleanup();
        };
    }, [updateState, isSyncing]);

    useEffect(() => {
        const storageKey = initialState.isTeam ? 
            `teamState_${initialState.teamId}` : 
            `questState_${initialState.questId}`;
        const savedState = localStorage.getItem(storageKey);
        
        if (savedState) {
            try {
                const parsed = JSON.parse(savedState);
                setState(prev => ({
                    ...prev,
                    ...parsed,
                }));
            } catch (error) {
                console.error('Error restoring state:', error);
            }
        }
    }, [initialState.questId, initialState.teamId, initialState.isTeam]);

    const value = {
        state,
        updateState,
        applyHint,
        addIncorrectAttempt,
        isSyncing,
    };

    return (
        <QuestStateContext.Provider value={value}>
            {children}
        </QuestStateContext.Provider>
    );
};

QuestStateProvider.propTypes = {
    children: PropTypes.node.isRequired,
    initialState: PropTypes.shape({
        questId: PropTypes.string,
        currentStep: PropTypes.number,
        currentSubStep: PropTypes.number,
        points: PropTypes.number,
        role: PropTypes.string,
        isTeam: PropTypes.bool,
        teamId: PropTypes.string,
        incorrectAttempts: PropTypes.number,
        usedHints: PropTypes.arrayOf(PropTypes.string),
        completedSteps: PropTypes.arrayOf(PropTypes.number),
    }),
};

QuestStateProvider.defaultProps = {
    initialState: {
        questId: '',
        currentStep: 1,
        currentSubStep: 0,
        points: 100,
        role: '',
        isTeam: false,
        teamId: null,
        incorrectAttempts: 0,
        usedHints: [],
        completedSteps: [],
    },
};

export default QuestStateProvider;

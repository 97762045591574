import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import { Play, Pause, Lightbulb, Send, Loader2, Volume2 } from 'lucide-react';
import useAudioStore from '../hooks/useAudioStore';

const RiddleStage = ({
    character,
    text,
    audioFile,
    questId,
    inputText,
    onInputChange,
    onSubmit,
    showHint,
    onHint,
    resultMessage,
    isProcessing,
    hint,
    isTeam = false,
    teamId = null,
    role = ''
}) => {
    const [hintText, setHintText] = useState('');
    const [canProceed, setCanProceed] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
    const [showErrorAnimation, setShowErrorAnimation] = useState(false);
    const [hasAttempted, setHasAttempted] = useState(false);
    const [localError, setLocalError] = useState(null);
    const [audioCompleted, setAudioCompleted] = useState(false);

    const {
        sound,
        isPlaying,
        progress,
        duration,
        isLoading: audioLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        setVolume,
        cleanup
    } = useAudioStore();

    // Определяем, может ли пользователь вводить ответ
    const canInput = !isTeam || (isTeam && ['Gamer', 'Regular'].includes(role));

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 100);
        
        if (audioFile) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {
                    onend: () => {
                        setAudioCompleted(true);
                        setCanProceed(true);
                    },
                    onload: () => {
                        if (!audioFile) {
                            setCanProceed(true);
                        }
                    },
                    onloaderror: (id, error) => {
                        setLocalError(`Failed to load audio: ${error}`);
                        setCanProceed(true);
                    }
                });
            } catch (error) {
                console.error('Error initializing audio:', error);
                setLocalError('Error loading audio file');
                setCanProceed(true);
            }
        } else {
            setCanProceed(true);
        }

        return () => {
            clearTimeout(timer);
            cleanup();
        };
    }, [audioFile, questId, initSound, cleanup]);

    useEffect(() => {
        if (resultMessage) {
            if (resultMessage.includes('Correct')) {
                setShowSuccessAnimation(true);
                setTimeout(() => setShowSuccessAnimation(false), 1500);
            } else {
                setShowErrorAnimation(true);
                setTimeout(() => setShowErrorAnimation(false), 1500);
            }
            setHasAttempted(true);
        }
    }, [resultMessage]);

    const handleHintClick = () => {
        setHintText(hint);
        if (onHint) {
            onHint();
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (canProceed && inputText.trim() && canInput) {
            onSubmit(e);
        }
    };

    const renderRiddleText = () => {
        const sentences = text.split('. ');
        return sentences.map((sentence, index) => (
            <div
                key={index}
                className="opacity-0 animate-fade-in"
                style={{
                    animationDelay: `${index * 300}ms`,
                    animationFillMode: 'forwards'
                }}
            >
                {sentence.trim()}{index < sentences.length - 1 ? '.' : ''}
            </div>
        ));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && canProceed && inputText.trim() && canInput && !isProcessing) {
            handleSubmit(e);
        }
    };

    return (
        <Card 
            className={`w-full max-w-3xl mx-auto transition-all duration-500 transform ${
                isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}
        >
            <CardHeader>
                <CardTitle className="text-xl md:text-2xl text-primary font-serif">
                    {character}
                    {isTeam && (
                        <span className="text-sm ml-2 text-muted-foreground">
                            ({role || 'No Role'})
                        </span>
                    )}
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-6">
                {audioFile && (
                    <div className="space-y-4">
                        <Button
                            onClick={togglePlayPause}
                            disabled={audioLoading}
                            className="w-full flex items-center justify-center space-x-2"
                            variant="outline"
                        >
                            {audioLoading ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : isPlaying ? (
                                <><Pause className="h-5 w-5" /> <span>Pause</span></>
                            ) : (
                                <><Play className="h-5 w-5" /> <span>Play Audio</span></>
                            )}
                        </Button>

                        {!audioError && (
                            <div className="space-y-2">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-primary rounded-full h-full transition-all duration-300"
                                        style={{ width: `${progress}%` }}
                                    />
                                </div>
                                <div className="flex items-center justify-between text-sm text-muted-foreground">
                                    <span>Progress: {Math.round(progress)}%</span>
                                    <div className="flex items-center gap-2">
                                        <Volume2 className="h-4 w-4" />
                                        <input 
                                            type="range"
                                            min="0"
                                            max="100"
                                            className="w-24"
                                            onChange={(e) => setVolume(e.target.value / 100)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                )}

                <div className="prose max-w-none space-y-4">
                    {renderRiddleText()}
                </div>

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="relative">
                        <Input
                            type="text"
                            value={inputText}
                            onChange={onInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder={canInput ? "Enter your answer" : "Waiting for team members..."}
                            disabled={!canProceed || isProcessing || !canInput}
                            className={`w-full transition-all duration-300 ${
                                hasAttempted && resultMessage.includes('Correct')
                                    ? 'border-green-500 focus:ring-green-500'
                                    : hasAttempted
                                    ? 'border-red-500 focus:ring-red-500'
                                    : ''
                            }`}
                        />
                    </div>

                    <div className="flex flex-col sm:flex-row gap-4">
                        {canInput && (
                            <Button
                                type="submit"
                                disabled={!canProceed || isProcessing || !inputText.trim()}
                                className={`flex-1 ${showSuccessAnimation ? 'animate-success' : ''} ${
                                    showErrorAnimation ? 'animate-error' : ''
                                }`}
                            >
                                {isProcessing ? (
                                    <Loader2 className="h-5 w-5 animate-spin" />
                                ) : (
                                    <><Send className="h-5 w-5 mr-2" /> Submit</>
                                )}
                            </Button>
                        )}

                        {showHint && (
                            <Button
                                type="button"
                                variant="outline"
                                onClick={handleHintClick}
                                disabled={isProcessing || !canProceed}
                                className="flex-1"
                            >
                                <Lightbulb className="h-5 w-5 mr-2" />
                                Get Hint (-10 points)
                            </Button>
                        )}
                    </div>
                </form>

                {hintText && (
                    <Alert>
                        <AlertDescription>{hintText}</AlertDescription>
                    </Alert>
                )}

                {resultMessage && (
                    <Alert variant={resultMessage.includes('Correct') ? 'default' : 'destructive'}>
                        <AlertDescription>{resultMessage}</AlertDescription>
                    </Alert>
                )}

                {localError && (
                    <Alert variant="destructive">
                        <AlertDescription>{localError}</AlertDescription>
                    </Alert>
                )}

                {!canProceed && audioFile && (
                    <div className="text-center text-sm text-muted-foreground animate-pulse">
                        Please listen to the audio before proceeding
                    </div>
                )}

                {isTeam && !canInput && (
                    <Alert>
                        <AlertDescription>
                            Waiting for team members with the appropriate role to solve this riddle
                        </AlertDescription>
                    </Alert>
                )}
            </CardContent>
        </Card>
    );
};

RiddleStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    inputText: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showHint: PropTypes.bool,
    onHint: PropTypes.func,
    resultMessage: PropTypes.string,
    isProcessing: PropTypes.bool,
    hint: PropTypes.string,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string
};

export default RiddleStage;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button } from './ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { Alert, AlertDescription } from './ui/alert';
import {
    Image,
    Upload,
    X,
    Camera,
    Lightbulb,
    FileImage,
    Loader2,
    Volume2,
    RefreshCcw,
} from 'lucide-react';
import useAudioStore from '../hooks/useAudioStore';

const ImageStage = ({
    character,
    text,
    audioFile,
    questId,
    onFileChange,
    onSubmit,
    showHint,
    onHint,
    resultMessage,
    isProcessing,
    hint,
    isTeam = false,
    teamId = null,
    role = ''
}) => {
    const [hintText, setHintText] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const [uploadError, setUploadError] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [audioCompleted, setAudioCompleted] = useState(false);
    const [canProceed, setCanProceed] = useState(false);

    const {
        sound,
        isPlaying,
        progress: audioProgress,
        duration,
        isLoading: audioLoading,
        error: audioError,
        initSound,
        togglePlayPause,
        setVolume,
        cleanup
    } = useAudioStore();

    // Проверка, может ли пользователь загружать изображения в командном режиме
    const canUploadImage = !isTeam || (isTeam && ['Gamer', 'Regular'].includes(role));

    useEffect(() => {
        const timer = setTimeout(() => setIsVisible(true), 100);
        
        if (audioFile) {
            const audioUrl = `/audio/quests/${questId}/${audioFile}`;
            try {
                initSound(audioUrl, {
                    onend: () => {
                        setAudioCompleted(true);
                        setCanProceed(true);
                    },
                    onload: () => {
                        if (!audioFile) {
                            setCanProceed(true);
                        }
                    },
                    onloaderror: (id, error) => {
                        console.error('Audio load error:', error);
                        setCanProceed(true);
                    }
                });
            } catch (error) {
                console.error('Error initializing audio:', error);
                setCanProceed(true);
            }
        } else {
            setCanProceed(true);
        }

        return () => {
            clearTimeout(timer);
            cleanup();
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [audioFile, questId, initSound, cleanup, previewUrl]);

    const handleFileSelect = useCallback(
        (file) => {
            if (!canUploadImage) return;

            if (file) {
                // Проверка типа файла
                if (!file.type.startsWith('image/')) {
                    setUploadError('Please select an image file');
                    return;
                }

                // Проверка размера файла (максимум 5MB)
                if (file.size > 5 * 1024 *1024) {
                    setUploadError('File size should not exceed 5MB');
                    return;
                }

                const reader = new FileReader();
                reader.onprogress = (e) => {
                    if (e.lengthComputable) {
                        const progress = (e.loaded / e.total) * 100;
                        setUploadProgress(progress);
                    }
                };

                reader.onload = () => {
                    setUploadProgress(100);
                    if (previewUrl) {
                        URL.revokeObjectURL(previewUrl);
                    }
                    setPreviewUrl(URL.createObjectURL(file));
                    setImageFile(file);
                    setUploadError('');
                    onFileChange({ target: { files: [file] } });
                };

                reader.onerror = () => {
                    setUploadError('Error reading file');
                };

                reader.readAsDataURL(file);
            }
        },
        [onFileChange, canUploadImage, previewUrl]
    );

    const handleDrop = useCallback(
        (e) => {
            e.preventDefault();
            if (!canUploadImage) return;
            setIsDragging(false);
            const file = e.dataTransfer.files[0];
            handleFileSelect(file);
        },
        [handleFileSelect, canUploadImage]
    );

    const handleDragOver = useCallback(
        (e) => {
            e.preventDefault();
            if (canUploadImage) {
                setIsDragging(true);
            }
        },
        [canUploadImage]
    );

    const handleDragLeave = useCallback((e) => {
        e.preventDefault();
        setIsDragging(false);
    }, []);

    const handleHintClick = () => {
        setHintText(hint);
        if (onHint) {
            onHint();
        }
    };

    const handleSubmitImage = async (e) => {
        e.preventDefault();
        if (imageFile && canUploadImage && canProceed) {
            setUploadProgress(0);
            const mockProgress = setInterval(() => {
                setUploadProgress((prev) => {
                    if (prev >= 90) {
                        clearInterval(mockProgress);
                        return 90;
                    }
                    return prev + 10;
                });
            }, 200);

            await onSubmit(e);
            clearInterval(mockProgress);
            setUploadProgress(100);
        }
    };

    const removeImage = () => {
        if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
        }
        setPreviewUrl('');
        setImageFile(null);
        setUploadProgress(0);
    };

    const renderImageText = () => {
        const sentences = text.split('. ');
        return sentences.map((sentence, index) => (
            <div
                key={index}
                className="opacity-0 animate-fade-in"
                style={{
                    animationDelay: `${index * 300}ms`,
                    animationFillMode: 'forwards'
                }}
            >
                {sentence.trim()}{index < sentences.length - 1 ? '.' : ''}
            </div>
        ));
    };

    return (
        <Card
            className={`w-full max-w-3xl mx-auto transition-all duration-500 transform ${
                isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'
            }`}
        >
            <CardHeader>
                <CardTitle className="text-xl md:text-2xl text-primary font-serif">
                    {character}
                    {isTeam && (
                        <span className="text-sm ml-2 text-muted-foreground">
                            ({role || 'No Role'})
                        </span>
                    )}
                </CardTitle>
            </CardHeader>

            <CardContent className="space-y-6">
                {audioFile && (
                    <div className="space-y-4">
                        <Button
                            onClick={togglePlayPause}
                            disabled={audioLoading}
                            className="w-full flex items-center justify-center space-x-2"
                            variant="outline"
                        >
                            {audioLoading ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : isPlaying ? (
                                <>
                                    <RefreshCcw className="h-5 w-5" /> 
                                    <span>Pause Audio</span>
                                </>
                            ) : (
                                <>
                                    <Volume2 className="h-5 w-5" /> 
                                    <span>Play Audio</span>
                                </>
                            )}
                        </Button>

                        {!audioError && (
                            <div className="space-y-2">
                                <div className="w-full bg-gray-200 rounded-full h-2">
                                    <div
                                        className="bg-primary rounded-full h-full transition-all duration-300"
                                        style={{ width: `${audioProgress}%` }}
                                    />
                                </div>
                                <div className="flex items-center justify-between text-sm text-muted-foreground">
                                    <span>Progress: {Math.round(audioProgress)}%</span>
                                    <div className="flex items-center gap-2">
                                        <Volume2 className="h-4 w-4" />
                                        <input 
                                            type="range"
                                            min="0"
                                            max="100"
                                            className="w-24"
                                            onChange={(e) => setVolume(e.target.value / 100)}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {audioError && (
                            <Alert variant="destructive">
                                <AlertDescription>{audioError}</AlertDescription>
                            </Alert>
                        )}
                    </div>
                )}

                <div className="prose max-w-none space-y-4">
                    {renderImageText()}
                </div>

                {canUploadImage && (
                    <div
                        className={`relative border-2 border-dashed rounded-lg p-6 transition-all duration-300 ${
                            isDragging
                                ? 'border-primary bg-primary/10'
                                : 'border-gray-300 hover:border-primary'
                        }`}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                    >
                        {!previewUrl ? (
                            <div className="flex flex-col items-center justify-center space-y-4">
                                <div className="rounded-full bg-primary/10 p-4">
                                    <FileImage className="w-8 h-8 text-primary" />
                                </div>
                                <div className="text-center">
                                    <p className="text-sm font-medium">
                                        Drag and drop your image here, or{' '}
                                        <label className="ml-1 text-primary cursor-pointer hover:underline">
                                            browse
                                            <input
                                                type="file"
                                                className="hidden"
                                                accept="image/*"
                                                onChange={(e) => handleFileSelect(e.target.files[0])}
                                                disabled={isProcessing}
                                            />
                                        </label>
                                    </p>
                                    <p className="text-xs text-muted-foreground mt-2">
                                        Supports: JPG, PNG, GIF (up to 5MB)
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div className="relative rounded-lg overflow-hidden">
                                    <img
                                        src={previewUrl}
                                        alt="Preview"
                                        className="w-full h-auto max-h-[400px] object-contain"
                                    />
                                    <Button
                                        variant="destructive"
                                        size="icon"
                                        className="absolute top-2 right-2"
                                        onClick={removeImage}
                                    >
                                        <X className="w-4 h-4" />
                                    </Button>
                                </div>
                                {uploadProgress > 0 && uploadProgress < 100 && (
                                    <div className="w-full bg-gray-200 rounded-full h-2">
                                        <div
                                            className="bg-primary rounded-full h-full transition-all duration-300"
                                            style={{ width: `${uploadProgress}%` }}
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}

                {uploadError && (
                    <Alert variant="destructive">
                        <AlertDescription>{uploadError}</AlertDescription>
                    </Alert>
                )}

                <div className="flex flex-col sm:flex-row gap-4">
                    {canUploadImage && (
                        <Button
                            onClick={handleSubmitImage}
                            disabled={!imageFile || isProcessing || !canProceed}
                            className="flex-1"
                        >
                            {isProcessing ? (
                                <>
                                    <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    <Camera className="w-4 h-4 mr-2" />
                                    Submit Image
                                </>
                            )}
                        </Button>
                    )}

                    {showHint && (
                        <Button
                            variant="outline"
                            onClick={handleHintClick}
                            disabled={isProcessing}
                            className="flex-1"
                        >
                            <Lightbulb className="w-4 h-4 mr-2" />
                            Get Hint (-10 points)
                        </Button>
                    )}
                </div>

                {hintText && (
                    <Alert>
                        <AlertDescription>{hintText}</AlertDescription>
                    </Alert>
                )}

                {resultMessage && (
                    <Alert
                        variant={resultMessage.includes('matches') ? 'default' : 'destructive'}
                    >
                        <AlertDescription>{resultMessage}</AlertDescription>
                    </Alert>
                )}

                {!canProceed && audioFile && (
                    <div className="text-center text-sm text-muted-foreground animate-pulse">
                        Please listen to the audio before proceeding
                    </div>
                )}

                {isTeam && !canUploadImage && (
                    <Alert>
                        <AlertDescription>
                            Waiting for team members with the appropriate role to upload and
                            check images
                        </AlertDescription>
                    </Alert>
                )}
            </CardContent>
        </Card>
    );
};

ImageStage.propTypes = {
    character: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    audioFile: PropTypes.string,
    questId: PropTypes.string.isRequired,
    onFileChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    showHint: PropTypes.bool,
    onHint: PropTypes.func,
    resultMessage: PropTypes.string,
    isProcessing: PropTypes.bool,
    hint: PropTypes.string,
    isTeam: PropTypes.bool,
    teamId: PropTypes.string,
    role: PropTypes.string,
};

export default ImageStage;

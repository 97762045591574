import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Alert, AlertDescription } from './ui/alert';
import {
  Users,
  Plus,
  Loader2,
  AlertCircle,
  Crown,
  Shield,
  Info
} from 'lucide-react';
import api from '../api';

const TeamCreate = () => {
  const [teamName, setTeamName] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();
  const { id: questId } = useParams();

  // Get data from localStorage
  const questModeData = (() => {
    try {
      return JSON.parse(localStorage.getItem(`questMode_${questId}`)) || {};
    } catch (error) {
      console.error('Error parsing quest mode data:', error);
      return {};
    }
  })();
  const participants = questModeData.participants || 2;

  const handleCreateTeam = async () => {
    if (!teamName.trim()) {
      setError('Please enter a team name');
      return;
    }

    try {
      setIsCreating(true);
      setError(null);

      console.log('Creating team with data:', {
        name: teamName.trim(),
        quest_id: questId,
        participants: participants
      });

      const response = await api.post('/team/create', {
        name: teamName.trim(),
        quest_id: questId,
        participants: participants
      });

      if (response.data && response.data.team_id) {
        console.log('Team created successfully:', response.data);

        // Обновляем questMode
        const updatedQuestModeData = {
          ...questModeData,
          isTeam: true,
          role: 'Leader',
          teamId: response.data.team_id
        };
        localStorage.setItem(`questMode_${questId}`, JSON.stringify(updatedQuestModeData));

        // Сохраняем код команды
        localStorage.setItem(`teamCode_${response.data.team_id}`, response.data.code);

        // Переходим на страницу ожидания
        navigate(`/team/${response.data.team_id}/waiting`);
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      console.error('Error creating team:', error);
      setError(
        error.response?.data?.detail ||
        error.message ||
        'Failed to create team'
      );
    } finally {
      setIsCreating(false);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setTeamName(value);
    if (value.length > 30) {
      setError('Team name must be 30 characters or less');
    } else {
      setError(null);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && teamName.trim() && !isCreating) {
      handleCreateTeam();
    }
  };

  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Users className="h-5 w-5" />
          Create Team
        </CardTitle>
        <CardDescription>
          Set up your team for the quest adventure
        </CardDescription>
      </CardHeader>

      <CardContent className="space-y-4">
        {error && (
          <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <div className="space-y-2">
          <label className="text-sm font-medium flex items-center justify-between">
            <span>Team Name</span>
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={() => setShowInfo(!showInfo)}
            >
              <Info className="h-4 w-4" />
            </Button>
          </label>
          <Input
            placeholder="Enter team name"
            value={teamName}
            onChange={handleNameChange}
            onKeyPress={handleKeyPress}
            disabled={isCreating}
            maxLength={30}
            className="font-medium"
          />
          {showInfo && (
            <Alert className="mt-2">
              <AlertDescription>
                Choose a unique and memorable team name that represents your group.
                The name will be visible to all team members.
              </AlertDescription>
            </Alert>
          )}
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium">Team Size</label>
          <div className="flex items-center gap-2">
            <Input
              type="number"
              value={participants}
              disabled={true}
              className="w-24"
            />
            <span className="text-sm text-muted-foreground">
              (Fixed number of players from purchase)
            </span>
          </div>
        </div>

        <div className="space-y-4 mt-6">
          <div className="rounded-lg border bg-card p-4">
            <h4 className="text-sm font-medium mb-2 flex items-center gap-2">
              <Crown className="h-4 w-4 text-yellow-500" />
              Team Leader Role
            </h4>
            <p className="text-sm text-muted-foreground">
              As the team creator, you'll automatically become the team leader.
              You'll be responsible for:
            </p>
            <ul className="mt-2 space-y-1 text-sm text-muted-foreground">
              <li>• Managing team formation</li>
              <li>• Starting the quest when everyone is ready</li>
              <li>• Coordinating team activities</li>
            </ul>
          </div>

          <div className="rounded-lg border bg-card p-4">
            <h4 className="text-sm font-medium mb-2 flex items-center gap-2">
              <Shield className="h-4 w-4 text-primary" />
              Team Member Roles
            </h4>
            <p className="text-sm text-muted-foreground">
              Team members can choose from these roles:
            </p>
            <ul className="mt-2 space-y-1 text-sm text-muted-foreground">
              <li>• Gamer - Can submit answers and images</li>
              <li>• Listener - Helps with audio puzzles</li>
              <li>• Regular - Can perform all basic tasks</li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row gap-2">
          <Button
            className="flex-1"
            onClick={handleCreateTeam}
            disabled={isCreating || !teamName.trim() || teamName.length > 30}
          >
            {isCreating ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Creating...
              </>
            ) : (
              <>
                <Plus className="mr-2 h-4 w-4" />
                Create Team
              </>
            )}
          </Button>

          <Button
            variant="outline"
            onClick={() => navigate('/')}
            disabled={isCreating}
          >
            Cancel
          </Button>
        </div>

        <Alert className="mt-4">
          <AlertDescription>
            Once the team is created, you'll receive a unique team code to
            share with your team members so they can join.
          </AlertDescription>
        </Alert>
      </CardContent>
    </Card>
  );
};

export default TeamCreate;

